<template>
  <div class="index-section row">
    <Card v-for="(card, index) in data" :key="index" class="card-header-auto">
      <template v-slot:header>
        <div class="index-header red-left">
          <img
            class="img-cover"
            :src="card.imageHighlight.imageUrl"
            :alt="card.imageHighlightimageAlt"
          />
        </div>
        <div class="badge-container">
          <div class="badge" v-if="badgeType">{{ badgeType }}</div>
        </div>
      </template>
      <template v-slot:content>
        <p class="index-title">{{ card.title }}</p>
        <p class="index-excerpt">{{ card.excerpt }}</p>
      </template>
      <template v-slot:outisde-card v-if="indexOptions.button">
        <div class="offset-btn">
          <Button
            btnLabel="Saber Mais"
            :btnName="indexOptions.link"
            :btnParams="{ slug: card.slug }"
            btnClass="primary-btn "
          />
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import Button from "@/components/Buttons/Button.vue";
import Card from "@/components/Card/Card.vue";

export default {
  name: "IndexBlock",
  components: {
    Button,
    Card,
  },
  props: {
    data: { type: Array },
    indexOptions: { type: Object },
    badgeType: { type: String },
  },
};
</script>

<style lang="scss" scoped>
@import "/Index";
</style>
